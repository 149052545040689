import React from 'react'
import { Grid, Typography } from '@mui/material'
import { IFeedbacksProps } from '@services/feedbackuserservice'
import FeedbackDataListItem from './feedbackDataListItem'
import FeedbackFiles from './feedbackFiles'
import { FormattedMessage } from 'react-intl'

type Props = {
  feedback: IFeedbacksProps
  files: Blob[] | undefined
}

const FeedbackDataList = ({ feedback, files }: Props) => {
  const data = feedback.data

  const feedbackItems: { questionId: string; answer: string }[] = [
    {
      questionId: 'page.feedbacks.form.action.topic',
      answer: data.topicOfAction,
    },
    { questionId: 'page.feedbacks.form.action.date', answer: data.date },
    {
      questionId: 'page.feedbacks.form.action.duration',
      answer: !data.duration
        ? 'null'
        : Number(data.duration) >= 2
        ? `${data.duration} Stunden`
        : `${data.duration} Stunde`,
    },
    { questionId: 'page.feedbacks.form.action.venue', answer: data.venue },
    { questionId: 'page.feedbacks.form.action.place', answer: data.place },
    {
      questionId: 'page.feedbacks.form.action.occasion',
      answer: data.occasion,
    },
    {
      questionId: 'page.feedbacks.form.action.bigevent',
      answer: data.bigEvent ? 'true' : 'false',
    },
    {
      questionId: 'page.feedbacks.form.action.smallevent',
      answer: data.smallEvent ? 'true' : 'false',
    },
    {
      questionId: 'page.feedbacks.form.action.visitors',
      answer: data.visitors ? data.visitors : 'null',
    },
    {
      questionId: 'page.feedbacks.form.action.materials',
      answer: data.materials,
    },
    {
      questionId: 'page.feedbacks.form.action.materialsAmount',
      answer: data.materialsAmount,
    },
    {
      questionId: 'page.feedbacks.form.action.missingMaterials',
      answer: data.missingMaterials,
    },
    {
      questionId: 'page.feedbacks.form.action.ambassadors',
      answer: data.ambassadors,
    },
    {
      questionId: 'page.feedbacks.form.action.organisation',
      answer: data.organisation,
    },
    {
      questionId: 'page.feedbacks.form.action.wishes',
      answer: data.wishes ? data.wishes : 'null',
    },
    {
      questionId: 'page.feedbacks.form.action.drugs',
      answer: data.drugs ? data.drugs : 'null',
    },
    {
      questionId: 'page.feedbacks.form.action.alcohol',
      answer: data.alcohol ? data.alcohol : 'null',
    },
    {
      questionId: 'page.feedbacks.form.action.satisfied',
      answer: data.satisfied ? data.satisfied : 'null',
    },
    {
      questionId: 'page.feedbacks.form.action.technicalDefects',
      answer: data.technicalDefects ? data.technicalDefects : 'null',
    },
  ]

  const senderItems: { questionId: string; answer: string }[] = [
    {
      questionId: 'page.feedbacks.form.sender.regions',
      answer: data.regions,
    },
    {
      questionId: 'page.feedbacks.form.sender.ministry',
      answer: data.ministry,
    },
    {
      questionId: 'page.feedbacks.form.sender.lastname',
      answer: data.lastname,
    },
    {
      questionId: 'page.feedbacks.form.sender.firstname',
      answer: data.firstname,
    },
    { questionId: 'page.feedbacks.form.sender.address', answer: data.address },
    { questionId: 'page.feedbacks.form.sender.email', answer: data.email },
    { questionId: 'page.feedbacks.form.sender.phone', answer: data.phone },
    {
      questionId: 'page.feedbacks.form.sender.termsOfPrivacy',
      answer: data.termsOfPrivacy ? 'true' : 'false',
    },
  ]

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      justifyContent="space-between"
      key={feedback.id}
    >
      <Typography
        variant="h3"
        sx={{
          my: 4,
        }}
      >
        <FormattedMessage id="page.feedbacks.action.information" />
      </Typography>

      {feedbackItems.map((item, index) => (
        <FeedbackDataListItem
          key={index}
          questionId={item.questionId}
          answer={item.answer}
          />
      ))}
      <Typography
        variant="h3"
        sx={{
          my: 4,
        }}
      >
        <FormattedMessage id="page.feedbacks.sender.information" />
      </Typography>

      {senderItems.map((item, index) => (
        <FeedbackDataListItem
          key={index}
          questionId={item.questionId}
          answer={item.answer}
        />
      ))}

      {!!files && files.length > 0 && (
        <>
          <Typography
            variant="h3"
            sx={{
              my: 4,
            }}
          >
            <FormattedMessage id="page.feedbacks.files.title" />
          </Typography>
          <Grid container spacing={2}>
            {files.map((file: Blob) => {
              return <FeedbackFiles id={feedback.id} file={file} />
            })}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default FeedbackDataList
