import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { parseISO, format } from 'date-fns'
import { de } from 'date-fns/locale'

import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Toolbar,
} from '@mui/material'
import useDialog from '@hooks/useDialog'
import { styled } from '@mui/system'
import {
  IFeedbacksProps,
  deleteFeedbacks,
  getFeedbacksFile,
} from '@services/feedbackuserservice'
import FeedbackDataList from './feedbackDataList'

const Info = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '1.4',
  color: theme.palette.primary.contrastText,
}))

export type FeedbackCardProps = AT.IReactPropsDefaults & {
  onActionClick?: () => void
  feedback: IFeedbacksProps
}

export default function FeedbackCard({
  onActionClick,
  feedback,
}: FeedbackCardProps) {
  const { toggle } = useDialog()
  const [files, setFiles] = useState<Array<Blob>>([])
  const [loading, setLoading] = useState(false)
  function formatDate(date: string, formatStr: string) {
    return format(parseISO(date), formatStr, {
      locale: de,
    })
  }

  useEffect(() => {
    setLoading(true)
    Promise.all(
      feedback.files.map((filePath) => {
        return getFeedbacksFile(filePath).then((res) => {
          return new Blob([res.data], {
            type: res.headers['content-type'],
          });
        });
      })
    )
      .then((downloadedFiles) => {
        setFiles((prevFiles) => [...prevFiles, ...downloadedFiles]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error downloading files:', error);
        setLoading(false);
      });
    }, [])

  if (!feedback.data) return null
  return (
    <Card
      elevation={3}
      sx={{
        width: '100%',
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: 'primary.main',
          p: 4,
        }}
      >
        <Grid
          container
          direction="row"
          spacing={1}
          wrap="nowrap"
          justifyContent="space-between"
          alignContent="flex-end"
        >
          <Grid item container direction="column" xs={8} spacing={0}>
            <Grid item>
              <Info>
                {feedback.data.firstname} {feedback.data.lastname}
              </Info>
            </Grid>
            <Grid item>
              <Info>{feedback.data.email}</Info>
            </Grid>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end">
            <Grid item>
              <Info>
                <FormattedMessage id="page.feedback.asked.date" />
                {formatDate(feedback.timestamp, "dd.MM.yyyy 'um' HH:mm 'Uhr'")}
              </Info>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <CardContent>
        <Grid
          sx={{
            mt: 2,
          }}
          container
          wrap="nowrap"
          spacing={2}
          direction="column"
        >
          {!loading && <FeedbackDataList feedback={feedback} files={files} />}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                toggle('areyousure', () =>
                  deleteFeedbacks(feedback.id).then(() => {
                    if (onActionClick) onActionClick()
                  })
                )
              }}
            >
              <FormattedMessage id="feedback.button.delete" />
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
