import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type Props = {
  id: string
  file: Blob
}

const FeedbackFiles = ({ id, file }: Props) => {
  const [loading, setLoading] = useState(false)
  const [foundUrl, setFoundUrl] = useState('')

  if (!file) {
    return <FormattedMessage id="page.feedbacks.files.empty" />
  }

  useEffect(() => {
    setLoading(true)
    const url = URL.createObjectURL(file)
    setFoundUrl(url)
    setLoading(false)
    return () => URL.revokeObjectURL(url)
  }, [file])

  return (
    <Grid item key={file.size} xs={12} sm={6} md={4} lg={3}>
      {!loading && foundUrl && (
        <img
          key={file.size + id}
          src={foundUrl}
          alt={`Uploaded Image ${file.size}`}
          style={{ maxWidth: '100px', cursor: 'pointer' }}
          onClick={() => window.open(foundUrl)}
        />
      )}
      {loading && <FormattedMessage id="page.feedbacks.files.loading" />}
    </Grid>
  )
}

export default FeedbackFiles
